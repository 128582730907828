import Welcome from './welcome';
import Why from './why';
import How from './how';
import When from './when';
import Who from './who';
import Privacy from './privacy';

export {
  Welcome,
  Why,
  How,
  When,
  Who,
  Privacy,
};
